<template>
  <li class="user-list">
    <div class="user-list-avatar">
      <img :src="imageUrl" alt="user default avatar" @error="getDefaultPic" />
      <font-awesome-icon
        class="iscircle"
        :class="[user.is_online ? 'online' : 'offline']"
        icon="circle"
      />
    </div>
    <div class="user-list-info1">
      <h4>{{ user.name }}</h4>
      <p>{{ user.email }}</p>
      <div>{{ user.phone }}</div>
    </div>
    <div class="user-list-info2">
      <div class="flex-row">
        <p>{{ user.role.display_name }}</p>
        <base-button 
          mode="square"
          @click="$emit('square-click', user.id)"
          ><font-awesome-icon icon="edit" />
        </base-button>
      </div>
      <p class="date" v-if="user.last_online">{{ user.last_online | lastOnline }}</p>
    </div>
  </li>
</template>

<script>
import default_avatar from "@/assets/images/default_avatar.png";
import moment from "moment";

export default {
  props: {
    user: {
      type: Object,
      required: true,
      default: {},
    },
  },
  filters: {
    lastOnline(date) {
      return date ? moment(date).format("DD MMM YYYY h:mm a") : "Not Available";
    }
  },
  data() {
    return {
      imageUrl: this.user.avatar_arr[0],
    };
  },
  computed: {
    getAvatar() {
      return this.user.avatar_arr[0];
    },
  },
  methods: {
    getDefaultPic() {
      this.imageUrl = default_avatar;
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
.user-list {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-size: 14px;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #eee;

  
  &-avatar {
    align-self: flex-start;
    position: relative;
    img {
      border-radius: 50%;
      width: 2.5rem;
      // position: relative;
    }
    .iscircle {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 10px;
    }
    .online {
      color: green;
    }
    .offline {
      color: red;
    }
  }
  &-info1 {
    // background: magenta;
    font-size: 14px;
    width: calc(70% - 2.5rem);
    display: flex;
    flex-direction: column;
    gap: .2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // border: 1px solid black;
  }
  &-info2 {
    justify-self: flex-end;
    width: calc(40% - 2.5rem);
    .flex-row{
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
    .date {
      text-align: center;
      font-size: $ten;
    }
  }

}
</style>